<template>
  <page-title-component title="Invitations" />

  <div class="row">
    <div class="col-12 layout-spacing">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-body py-4">
              <table-component :columns="columns"
                               :rows="invitations"
                               :search="false"
                               :pagination="pagination"
                               @pageChanged="pageChanged"
                               :sortable="sortable"
                               @sorting="sorting"
                               @search="search">
                <template v-slot:actions>
                  <button class="btn btn-sm btn-info"
                          @click="$refs.inviteModal.show">Invite</button>
                </template>


                <template v-slot:signed_up="{ row }">
                  <span class="badge badge-success" v-if="row.signed_up">Yes</span>
                  <span class="badge badge-warning" v-else>No</span>
                </template>

                <template v-slot:action="{ row }">
                  <button class="btn btn-sm btn-info"
                          @click="copyLink(row)">Copy Link</button>
                </template>
              </table-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <invite-modal @invited="loadData" ref="inviteModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import InviteModal from "@/components/InviteModal.vue";

export default {
  components: {InviteModal, PageTitleComponent, TableComponent},
  data() {
    return {
      invitations: [],
      pagination: null,
      queryParams: {
        page: 1,
        search: '',
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Email",
          field: "email",
          sortable: true,
          searchable: true,
        },
        {
          label: "Invited At",
          field: "created_at",
          sortable: true,
          searchable: false,
        },
        /*{
          label: "Signed Up",
          field: "signed_up",
          sortable: true,
          searchable: false,
          slot: true
        },*/
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-right']
        }
      ],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    copyLink(row) {
      let url = "https://user.attribution-insights.com/login?i_code=" + row.code;

      navigator.clipboard.writeText(url);
      this.showSuccessMsg("Link Copied!")
    },
    loadData() {
      this.invitations = [];

      this.axios.get('/invitations', {params: this.queryParams})
        .then((response) => {
          this.invitations = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>